import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Description from "../components/description"
import "../styles/page-careers.scss"

const CareersLink = ({ url }) => {
  return (
    <div>
      <a href={url} target="_blank" rel="noreferrer">Oferty pracy &rarr;</a>
    </div>
  )
}

const CareersEmail = ({ title, jobUrl, jobEmail }) => {
  return (
    <div>
      For Job Opportunities in {title}, please contact <a href={"mailto:" + jobEmail}>{jobEmail}</a>
    </div>
  )
}

const PageCareers = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout id="careers">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      <section className="container">
        <div className="container-narrow">
          <h1>{page.title}</h1>
          { page.Description.description && (
          <Description {...page.Description} />
          )}

          <div className="continents">
          {page.CareersData.continent.map(continent => {
            return (
              <div key={continent.title} className="continent-item">
                <h2>{continent.title}</h2>
                <ul>
                {continent.officeGroup.map(group => {
                  return (
                    <li key={group.title}>
                      <strong>{group.title}</strong>
                      {group.jobUrl ? <CareersLink url={group.jobUrl} /> : <CareersEmail {...group} />}
                    </li>
                  )
                })}
                </ul>
              </div>
            )
          })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PageCareers

export const query = graphql`
  query($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      Description {
        description
      }
      CareersData {
        continent {
          title
          officeGroup {
            title
            jobUrl
            jobEmail
          }
        }
      }
      SeoMeta {
        seoTitle
        seoDescription
      }
    }
  }`
  